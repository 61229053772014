import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  isError: null,
  isLoading: false,
  userListData: null,
  singleUserData: null,
  userContestData: null,
};

//** User List **\\
export const userList =
  ({ page, limit, searchUser }) =>
  async (dispatch) => {
    try {
      dispatch(userSetState([{ key: "isLoading", value: true }]));
      const response = await axiosApi.get(
        `admin/user/list?page=${page}&limit=${limit}&search=${searchUser}`,
        null
      );
      if (response) {
        dispatch(
          userSetState([
            { key: "isError", value: false },
            { key: "isLoading", value: false },
            { key: "userListData", value: response?.data?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(userSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };

//** Switch to Researcher **\\
export const switchToResearcher = (userId) => async (dispatch) => {
  try {
    dispatch(
      userSetState([
        { key: "isLoading", value: true },
        { key: "switchToResearcherData", value: null },
      ])
    );
    const response = await axiosApi.put(
      `admin/users/make-researcher/${userId}`,
      null
    );
    if (response) {
      toast.success(response?.data?.message);
      dispatch(
        userSetState([
          { key: "isError", value: false },
          { key: "isLoading", value: false },
          { key: "switchToResearcherData", value: response?.data?.message },
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(userSetState([{ key: "isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

// ** User Status **\\
export const userStatus = (userId) => async (dispatch) => {
  try {
    dispatch(
      userSetState([
        { key: "isLoading", value: true },
        { key: "userStatusData", value: null },
      ])
    );
    const response = await axiosApi.patch(`admin/user/active/${userId}`, null);
    if (response) {
      toast.success(response?.data?.message);
      dispatch(
        userSetState([
          { key: "isError", value: false },
          { key: "isLoading", value: false },
          { key: "userStatusData", value: response?.data?.message },
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(userSetState([{ key: "isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

//** Fetch Single User **\\
export const fetchSingleUser = (userId) => async (dispatch) => {
  try {
    dispatch(userSetState([{ key: "isLoading", value: true }]));
    const response = await axiosApi.get(`admin/user/get/${userId}`, null);
    if (response) {
      dispatch(
        userSetState([
          { key: "isError", value: false },
          { key: "isLoading", value: false },
          { key: "singleUserData", value: response?.data },
        ])
      );
    }
    return response;
  } catch (error) {
    dispatch(userSetState([{ key: "isLoading", value: false }]));
    toast.error(error.response?.data?.message);
  }
};

// ** Delete User **\\
export const deleteUserApi =
  ({ userId, setDeleteUserModal }) =>
  async (dispatch) => {
    try {
      dispatch(
        userSetState([
          { key: "isLoading", value: true },
          { key: "deleteUserData", value: null },
        ])
      );
      const response = await axiosApi.delete(
        `admin/user/delete/${userId}`,
        null
      );
      if (response) {
        toast.success(response?.data?.message);
        dispatch(
          userSetState([
            { key: "isError", value: null },
            { key: "isLoading", value: false },
            { key: "deleteUserData", value: response?.data?.message },
          ])
        );
        setDeleteUserModal(false);
      }
      return response;
    } catch (error) {
      dispatch(userSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };

//** User Contest List **\\
export const userContestList =
  ({ userId, page, limit }) =>
  async (dispatch) => {
    try {
      dispatch(userSetState([{ key: "isLoading", value: true }]));
      const response = await axiosApi.get(
        `admin/user/contest-history/${userId}`,
        {
          params: {
            page,
            limit,
          },
        }
      );
      if (response) {
        dispatch(
          userSetState([
            { key: "isError", value: false },
            { key: "isLoading", value: false },
            { key: "userContestData", value: response?.data?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(userSetState([{ key: "isLoading", value: false }]));
      toast.error(error.response?.data?.message);
    }
  };

const userSlice = createSlice({
  name: "user",
  initialState: initialStates,
  reducers: {
    userSetState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { userSetState } = userSlice.actions;

const { reducer } = userSlice;

export default reducer;
