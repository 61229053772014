import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  paymentListData: {
    data: null,
    isLoading: false,
    isError: false,
  },
};

// Payment Listing Api
export const paymentListingApi =
  ({ page, limit }) =>
  async (dispatch) => {
    try {
      dispatch(
        paymentState([{ key: "paymentListData.isLoading", value: true }])
      );

      const response = await axiosApi.get(
        `admin/payment/list?page=${page}&limit=${limit}`
      );
      if (response) {
        dispatch(
          paymentState([
            { key: "paymentListData.isError", value: false },
            { key: "paymentListData.isLoading", value: false },
            { key: "paymentListData.data", value: response?.data?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(
        paymentState([{ key: "paymentListData.isLoading", value: false }])
      );
      toast.error(error.response?.data?.message);
    }
  };

const paymentSlice = createSlice({
  name: "payment",
  initialState: initialStates,
  reducers: {
    paymentState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { paymentState } = paymentSlice.actions;

const { reducer } = paymentSlice;

export default reducer;
